import "./css/style.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  ButtonShapes,
  CampaignType,
  Offer,
  LayoutClass,
  QueryParams,
  SelectedOfferStorage,
  TemplateOptionSettings,
  Template,
  PlacementStage,
} from "../../../types/global.types";
import { getTrackingEvent } from "../../../services/offers/offers.service";
import TemplateSingle from "../single/TemplateSingle.component";
import SwiperComponent from "./swiper/Swiper.component";
import PreCountAndSelectAll from "../components/PreCountAndSelectAll/PreCountAndSelectAll";

interface Props {
  params: QueryParams;
  settings: TemplateOptionSettings;
  settingsTemplate: Template;
  campaignId: string;
  placementId: string;
  placementName: string;
  first: boolean;
  defaultOpen: boolean;
  campaignRes: CampaignType;
  type: LayoutClass;
  setOpen: (open: boolean) => void;
  selectedOffers: string[];
  buttonShape: ButtonShapes;
  email: string | undefined;
  slot: number;
  defaultSix: boolean;
}

const PreviewPopupFilters48 = ({
  params,
  email,
  settings,
  campaignId,
  first,
  campaignRes,
  placementId,
  placementName,
  defaultOpen,
  setOpen,
  selectedOffers,
  buttonShape,
  settingsTemplate,
  slot,
  defaultSix,
}: Props) => {
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [campaign, setCampaign] = useState<CampaignType | undefined>(
    campaignRes
  );

  const [preEmail, setPreEmail] = useState<string | undefined>();

  const [single, setSingle] = useState<Offer | undefined>();
  const [isSingleSave, setSingleSave] = useState<boolean>(false);
  const [isSingleShare, setSingleShare] = useState<boolean>(false);

  const [displayOnly] = useState<boolean>(
    campaignRes?.placementSettings[0]?.displayOnly
  );
  const refOffers = useRef<HTMLDivElement | null>(null);
  const [hasOpen, setHasOpen] = useState<boolean>(!first);
  const [offerHostType, setOfferHostType] = useState<string>("");
  const [offerFullList, setOfferFullList] = useState<Offer[]>(
    campaignRes.offers
  );
  const [offerFilteredList, setOfferFilteredList] = useState<Offer[]>(
    campaignRes.offers
  );
  const [selectedCategory, setSelectedCategory] = useState<string>("");

  const offersCategories = useMemo(() => {
    const cats: string[] = [];

    offerFullList.forEach((o) => {
      o.categories.forEach((cat) => {
        !cats.includes(cat.name) && cats.push(cat.name);
      });
    });

    return cats;
  }, [offerFullList]);

  useEffect(() => {
    const _offerFilteredList: Offer[] = [];
    const _offerFullList: Offer[] = [];
    campaignRes.offers.forEach((o) => {
      if (offerHostType) {
        setSelectedCategory("");
        if (
          (!o.offerSettings.isB2C && offerHostType === "trade") ||
          (o.offerSettings.isB2C && offerHostType === "personal") ||
          offerHostType === "all"
        ) {
          _offerFullList.push(o);
          _offerFilteredList.push(o);
        }
        setOfferFilteredList(_offerFilteredList);
        setOfferFullList(_offerFullList);
      }
    });
    // eslint-disable-next-line
  }, [offerHostType]);

  useEffect(() => {
    const _offerFilteredList: Offer[] = [];
    offerFullList.forEach((o) => {
      if (selectedCategory) {
        o.categories.forEach((cat) => {
          cat.name === selectedCategory && _offerFilteredList.push(o);
        });
      } else {
        _offerFilteredList.push(o);
      }
      setOfferFilteredList(_offerFilteredList);
    });
    // eslint-disable-next-line
  }, [selectedCategory]);

  const handleClose = () => {
    setOfferHostType("");
    window.top?.postMessage({ event: "closeMainIframe", load: "" }, "*");
    setSingle(undefined);
    setOpen(false);
    campaign &&
      getTrackingEvent({
        event: "ontemplateclose",
        retailerId: campaign.company_Id,
        campaignId,
        placementId,
        placementName,
        tid: settingsTemplate.identifier,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      });
    setCampaign(undefined);
  };

  const handleClickSingleWrapper = (event: React.MouseEvent<HTMLElement>) => {
    if (
      event.target instanceof Node &&
      !refOffers.current?.contains(event.target)
    ) {
      handleClose();
    }
  };

  const handleCloseSingle = () => {
    setSingle(undefined);
    setSingleSave(false);
  };

  const checkSelectedOffers = (
    selectedOffers: string[] = [],
    _campaign: CampaignType
  ) => {
    const newOffers: Offer[] = _campaign.offers.map((offer: Offer) => {
      if (selectedOffers.includes(offer.id)) {
        offer.isAdded = true;
      }
      return offer;
    });
    setCampaign(
      (prevState) => prevState && { ...prevState, offers: newOffers }
    );
  };

  const openSingle = (
    offer: Offer,
    isSave: boolean,
    isAutoEmail: boolean,
    slot: number,
    isShare?: boolean
  ) => {
    if (isAutoEmail) {
      const params = {
        offer,
        isSave,
        isAuto: true,
        displayOnly,
        campaign: campaign,
        settings,
        companyId: campaign?.company_Id,
        campaignId: campaign?.id,
        placementId,
        placementName,
        slot,
      };
      window.top?.postMessage({ event: "emailPopup", load: params }, "*");
    } else {
      setSingle(offer);
      setSingleSave(isSave);
      setSingleShare(isShare || false);
      window.top?.postMessage({ event: "singleChange", offer, slot }, "*");
    }
  };

  const handleAdd = (offer: Offer, value: boolean, slot: number) => {
    setSelectedOfferStorage({
      id: offer.id,
      link: offer.trackingUrl,
      active: value,
    });
    if (campaign) {
      const newOffers: Offer[] = campaign.offers.map((_offer: Offer) => {
        if (_offer.id === offer.id) {
          _offer.isAdded = value;
        }
        return _offer;
      });
      setCampaign(
        (prevState) => prevState && { ...prevState, offers: newOffers }
      );
    } else {
      const _single = { ...single, isAdded: value };
      setSingle((prevState) => prevState && { ...prevState, isAdded: value });
      window.top?.postMessage(
        { event: "singleChange", offer: _single, slot },
        "*"
      );
    }

    value &&
      campaign &&
      getTrackingEvent({
        event: "onselectoffer",
        offerId: offer.id,
        retailerId: campaign.company_Id,
        campaignId: campaign.id,
        placementId,
        placementName,
        tid: settingsTemplate.identifier,
        slot,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      });
  };

  const handleSelectAll = () => {
    if (!campaign) {
      return;
    }

    const selectedLength = campaign.offers.filter((o) =>
      selectedOffers.includes(o.id)
    ).length;
    const offerLength = campaign?.offers.length;
    const shouldRemove = selectedLength === offerLength;

    campaign.offers.forEach((offer: Offer) => {
      handleAdd(offer, !shouldRemove, offer.slot);
    });
  };

  const setSelectedOfferStorage = (storage: SelectedOfferStorage) => {
    window.top?.postMessage({ event: "setSelectedOffer", load: storage }, "*");
  };

  useEffect(() => {
    if (defaultOpen) {
      checkSelectedOffers(selectedOffers, campaignRes);
      setCampaign(campaignRes);
      if (campaignRes && !single) {
        hasOpen &&
          getTrackingEvent({
            event: "onmainbuttonclick",
            retailerId: campaignRes?.company_Id,
            campaignId,
            placementId,
            placementName,
            tid: settingsTemplate.identifier,
            pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
          });
        getTrackingEvent({
          event: "ontemplateopen",
          retailerId: campaignRes.company_Id,
          campaignId: campaignRes.id,
          placementId,
          placementName,
          tid: settingsTemplate.identifier,
          pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
        });
      }
      setHasOpen(true);
    }
    // eslint-disable-next-line
  }, [campaignRes, defaultOpen]);

  return (
    <>
      <div
        className={`preview-f-wrapper ${settings?.location.location}`}
        onClick={handleClickSingleWrapper}
      >
        <div
          className={`preview-f-popup`}
          ref={refOffers}
          style={{
            backgroundColor: settings?.mainLayout.backgroundColor,
            borderColor:
              settings?.mainLayout?.borderColor ||
              settings?.mainLayout.backgroundColor,
            transform: `translate(${settings?.location.x || 0}px, ${
              settings?.location.y || 0
            }px)`,
            fontFamily: settings?.mainLayout.fontFamily,
            color: settings?.mainLayout.textColor,
            maxHeight: settings?.location?.y
              ? settings?.location?.y < 0
                ? `calc(100vh - ${settings?.location.y * -1}px)`
                : `calc(100vh - ${settings?.location.y}px)`
              : "",
          }}
        >
          <div
            className="preview-f-minimize"
            onClick={() => handleClose()}
            style={{ backgroundColor: settings?.dealLayout.backgroundColor }}
          >
            <svg
              width="10"
              height="2"
              viewBox="0 0 10 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="10"
                height="2"
                rx="1"
                fill={settings?.dealLayout.textColor}
              />
            </svg>
          </div>

          <div className={`preview-f-title`}>
            {buttonShape === ButtonShapes.squareImage && (
              <>
                <div className="preview-f-title-icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.2 10V19H2.8V10M10 19V5.5M10 5.5H5.95C5.35326 5.5 4.78097 5.26295 4.35901 4.84099C3.93705 4.41903 3.7 3.84674 3.7 3.25C3.7 2.65326 3.93705 2.08097 4.35901 1.65901C4.78097 1.23705 5.35326 1 5.95 1C9.1 1 10 5.5 10 5.5ZM10 5.5H14.05C14.6467 5.5 15.219 5.26295 15.641 4.84099C16.0629 4.41903 16.3 3.84674 16.3 3.25C16.3 2.65326 16.0629 2.08097 15.641 1.65901C15.219 1.23705 14.6467 1 14.05 1C10.9 1 10 5.5 10 5.5ZM1 5.5H19V10H1V5.5Z"
                      stroke={settings?.mainLayout.headerTextColor}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            )}

            {buttonShape === ButtonShapes.squareImageLock && (
              <>
                <div className="preview-f-title-icon">
                  {displayOnly ? (
                    <svg
                      width="42"
                      height="47"
                      viewBox="0 0 42 47"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.88889 21.25V12.25C9.88889 9.26631 11.0595 6.40483 13.1433 4.29505C15.227 2.18526 18.0532 1 21 1C23.9469 1 26.773 2.18526 28.8567 4.29505C30.9405 6.40483 32.1111 9.26631 32.1111 12.25V21.25M5.44444 21.25H36.5556C39.0102 21.25 41 23.2647 41 25.75V41.5C41 43.9853 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9853 1 41.5V25.75C1 23.2647 2.98985 21.25 5.44444 21.25Z"
                        stroke={settings?.mainLayout.headerTextColor}
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="42"
                      height="47"
                      viewBox="0 0 42 47"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.88889 21.2554V12.258C9.88613 9.46896 10.9073 6.77834 12.7542 4.7085C14.601 2.63865 17.1419 1.33726 19.8834 1.05697C22.6248 0.776667 25.3714 1.53746 27.5899 3.19164C29.8084 4.84583 31.3406 7.2754 31.8889 10.0087M5.44444 21.2572H36.5556C39.0102 21.2572 41 23.2714 41 25.7559V41.5013C41 43.9859 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9859 1 41.5013V25.7559C1 23.2714 2.98985 21.2572 5.44444 21.2572Z"
                        stroke={settings?.mainLayout.headerTextColor}
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
              </>
            )}

            <span
              className={`preview-f-title-label`}
              style={{
                fontSize: settings?.mainLayout.headerFontSize + "px",
                fontWeight: settings?.mainLayout.headerFontWeight,
                color: settings?.mainLayout.headerTextColor,
              }}
            >
              {settings?.mainLayout.headerText || ""}
            </span>
          </div>
          <div
            className={`preview-f-description`}
            style={{
              fontSize: settings?.mainLayout.subHeaderFontSize + "px",
              fontWeight: settings?.mainLayout.subHeaderFontWeight,
              color: settings?.mainLayout.subHeaderTextColor,
            }}
          >
            {settings?.mainLayout.subHeaderText || ""}
          </div>

          {!offerHostType ? (
            <div className="preview-f-closedfilters">
              {!!campaignRes.offers.find((o) => o.offerSettings.isB2C) && (
                <div
                  className="preview-f-button"
                  onClick={() => setOfferHostType("personal")}
                  style={{
                    color: settings?.filters?.activeColor,
                    backgroundColor: settings?.filters?.backgroundColor,
                    borderColor: settings?.filters?.borderColor,
                  }}
                >
                  Show more personal offers
                </div>
              )}
              {!!campaignRes.offers.find((o) => !o.offerSettings.isB2C) && (
                <div
                  className="preview-f-button"
                  onClick={() => setOfferHostType("trade")}
                  style={{
                    color: settings?.filters?.activeColor,
                    backgroundColor: settings?.filters?.backgroundColor,
                    borderColor: settings?.filters?.borderColor,
                  }}
                >
                  Show more trade offers
                </div>
              )}
            </div>
          ) : (
            <div className="preview-f-openfilters">
              {!!campaignRes.offers.find((o) => !o.offerSettings.isB2C) &&
                !!campaignRes.offers.find((o) => o.offerSettings.isB2C) && (
                  <div className="preview-f-filter">
                    <ul
                      style={{
                        borderColor:
                          settings?.filters?.borderColor || "#939393",
                      }}
                    >
                      <li
                        onClick={() => setOfferHostType("personal")}
                        style={{
                          color:
                            offerHostType === "personal"
                              ? settings?.filters?.activeColor || "#0053a0"
                              : settings?.filters?.textColor || "#939393",
                          backgroundColor:
                            offerHostType === "personal"
                              ? settings?.filters?.backgroundColor || "#e5eef5"
                              : "transparent",
                          borderColor:
                            offerHostType === "personal"
                              ? settings?.filters?.activeColor || "#0053a0"
                              : "transparent",
                        }}
                      >
                        Personal offers
                      </li>

                      <li
                        onClick={() => setOfferHostType("trade")}
                        style={{
                          color:
                            offerHostType === "trade"
                              ? settings?.filters?.activeColor || "#0053a0"
                              : settings?.filters?.textColor || "#939393",
                          backgroundColor:
                            offerHostType === "trade"
                              ? settings?.filters?.backgroundColor || "#e5eef5"
                              : "transparent",
                          borderColor:
                            offerHostType === "trade"
                              ? settings?.filters?.activeColor || "#0053a0"
                              : "transparent",
                        }}
                      >
                        Trade offers
                      </li>
                      <li
                        onClick={() => setOfferHostType("all")}
                        style={{
                          color:
                            offerHostType === "all"
                              ? settings?.filters?.activeColor || "#0053a0"
                              : settings?.filters?.textColor || "#939393",
                          backgroundColor:
                            offerHostType === "all"
                              ? settings?.filters?.backgroundColor || "#e5eef5"
                              : "transparent",
                          borderColor:
                            offerHostType === "all"
                              ? settings?.filters?.activeColor || "#0053a0"
                              : "transparent",
                        }}
                      >
                        All
                      </li>
                    </ul>
                  </div>
                )}
              <div className="preview-f-filter">
                <ul
                  style={{
                    borderColor: settings?.filters?.borderColor || "#939393",
                  }}
                >
                  {offersCategories.map((category) => {
                    return (
                      <li
                        onClick={() =>
                          setSelectedCategory(
                            category === selectedCategory ? "" : category
                          )
                        }
                        style={{
                          color:
                            category === selectedCategory
                              ? settings?.filters?.activeColor || "#0053a0"
                              : settings?.filters?.textColor || "#939393",
                          backgroundColor:
                            category === selectedCategory
                              ? settings?.filters?.backgroundColor || "#e5eef5"
                              : "transparent",
                          borderColor:
                            category === selectedCategory
                              ? settings?.filters?.activeColor || "#0053a0"
                              : "transparent",
                        }}
                      >
                        {category}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}

          {displayOnly && campaign?.campaignGeneralOptions?.showAddToOrder && (
            <PreCountAndSelectAll
              offerLength={campaign?.offers.length}
              countText={settings?.mainLayout?.countText}
              selectText={settings?.mainLayout?.selectText}
              showCheckboxSelect={
                settings?.otherSettings?.showCheckboxSelect || false
              }
              noOfSelected={
                campaign?.offers.filter((o) => selectedOffers.includes(o.id))
                  .length
              }
              showSelectAll={settings?.otherSettings?.showSelectAll || false}
              handleSelectAll={handleSelectAll}
            />
          )}

          <div className={`preview-f-swiper`}>
            {campaign && (
              <SwiperComponent
                key="static-swiper"
                disabled={true}
                offers={
                  offerFilteredList.length > 3
                    ? offerFilteredList.slice(0, 4)
                    : offerFilteredList
                }
                settings={settings}
                campaign={campaign}
                defaultOpen={defaultOpen}
                settingsTemplate={settingsTemplate}
                displayOnly={displayOnly}
                campaignId={campaignId}
                placementId={placementId}
                placementName={placementName}
                openSingle={openSingle}
                onAdd={handleAdd}
                setPreEmail={setPreEmail}
                preEmail={preEmail || email}
              ></SwiperComponent>
            )}
          </div>
          <div className={`preview-f-swiper`}>
            {offerHostType && offerFilteredList.length > 3 && campaign && (
              <SwiperComponent
                key="static-swiper"
                disabled={false}
                offers={offerFilteredList.slice(4, offerFilteredList.length)}
                settings={settings}
                campaign={campaign}
                defaultOpen={defaultOpen}
                settingsTemplate={settingsTemplate}
                displayOnly={displayOnly}
                campaignId={campaignId}
                placementId={placementId}
                placementName={placementName}
                openSingle={openSingle}
                onAdd={handleAdd}
                setPreEmail={setPreEmail}
                preEmail={preEmail || email}
              ></SwiperComponent>
            )}
          </div>

          <div className="preview-f-footer">
            {displayOnly ? (
              <>
                {campaign?.campaignGeneralOptions.emailCaptureOnOffers && (
                  <>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="12"
                        fill={settings?.dealLayout.backgroundColor}
                      />
                      <path
                        d="M18 9.125C18 8.50625 17.46 8 16.8 8H7.2C6.54 8 6 8.50625 6 9.125M18 9.125V15.875C18 16.4938 17.46 17 16.8 17H7.2C6.54 17 6 16.4938 6 15.875V9.125M18 9.125L12 13.0625L6 9.125"
                        stroke={settings?.dealLayout.textColor}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Pick your gifts and receive by e-mail</span>
                  </>
                )}
              </>
            ) : (
              <>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z"
                    fill={settings?.mainLayout.textColor}
                  />
                </svg>
                <span>
                  By selecting the option above you will be directed to a third
                  party website. Any use of third party websites are not subject
                  to our Privacy Policy, and we encourage you to also read their
                  privacy statements
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      {single && campaign?.id && (
        <TemplateSingle
          slot={slot}
          settings={settings}
          isInline={false}
          offer={single}
          isSave={isSingleSave}
          isShare={isSingleShare}
          placementId={placementId}
          placementName={placementName}
          settingsTemplate={settingsTemplate}
          campaignObj={campaign}
          displayOnly={displayOnly}
          campaignId={campaign?.id}
          companyId={campaign.company_Id}
          updateCampaign={handleAdd}
          setPreEmail={setPreEmail}
          preEmail={preEmail}
          closeSignle={handleCloseSingle}
        />
      )}
    </>
  );
};

export default PreviewPopupFilters48;
