import "./css/style.scss";
import SwiperCore from "swiper";
import { Navigation, Controller } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperComponent from "./swiper/Swiper.component";
import { useEffect, useRef, useState } from "react";
import {
  ButtonShapes,
  CampaignType,
  LayoutDeviceSettings,
  Offer,
  PlacementStage,
  QueryParams,
  SelectedOfferStorage,
  TemplateOptionSettings,
} from "../../../types/global.types";
import { getTrackingEvent } from "../../../services/offers/offers.service";
import TemplateSingle from "../single/TemplateSingle.component";
import LayoutEmail from "../../layout/email";
import PreCountAndSelectAll from "../components/PreCountAndSelectAll/PreCountAndSelectAll";

SwiperCore.use([Navigation, Controller]);

interface Props {
  params: QueryParams;
  layoutSettings: LayoutDeviceSettings;
  placementId: string;
  placementName: string;
  campaignRes: CampaignType;
  selectedOffers: string[];
  singleOffer: Offer | undefined;
  isSave: boolean | undefined;
  isShare: boolean | undefined;
  isEmail: boolean;
  email: string | undefined;
  slot: number;
  setSingle: React.Dispatch<React.SetStateAction<Offer | undefined>>;
  setIsEmail: React.Dispatch<React.SetStateAction<boolean>>;
}

const PreviewEmbeddedS = ({
  params,
  layoutSettings,
  isSave,
  isShare,
  campaignRes,
  placementId,
  placementName,
  selectedOffers,
  singleOffer,
  setSingle,
  setIsEmail,
  isEmail,
  email,
  slot,
}: Props) => {
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const settingsTemplate = layoutSettings?.template;
  const buttonShape = layoutSettings.button.shape;
  const settings: TemplateOptionSettings | undefined = layoutSettings.settings;
  const swiperParentRef = useRef<HTMLDivElement>(null);
  const [displayOnly] = useState<boolean>(
    campaignRes?.placementSettings[0]?.displayOnly
  );
  const [campaign, setCampaign] = useState<CampaignType | undefined>(
    campaignRes
  );
  const [preEmail, setPreEmail] = useState<string | undefined>(email);
  const [isSingleSave, setSingleSave] = useState<boolean>(false);
  const [idsEmailSent, setIdsEmailSent] = useState<string[]>([]);

  const updateCampaignBySingle = (o: Offer) => {
    if (campaign) {
      const newOffers: Offer[] = campaign.offers.map((offer: Offer) => {
        if (offer.id === o.id) {
          return o;
        }
        return offer;
      });

      setCampaign(
        (prevState) => prevState && { ...prevState, offers: newOffers }
      );
    }
  };

  useEffect(() => {
    window.addEventListener("message", function (event) {
      if (Object.keys(event.data).includes("single")) {
        updateCampaignBySingle(event.data.single);
      }
    });

    return () => {
      window.removeEventListener("message", () => {});
    };
    // eslint-disable-next-line
  }, [campaign]);

  const handleCloseSingle = (emailSent: boolean, id: string) => {
    emailSent && setIdsEmailSent((prevState) => [...prevState, id]);
    setSingle(undefined);
    setSingleSave(false);
  };

  const checkSelectedOffers = (
    selectedOffers: string[] = [],
    _campaign: CampaignType
  ) => {
    const newOffers: Offer[] = _campaign.offers.map((offer: Offer) => {
      if (selectedOffers.includes(offer.id)) {
        offer.isAdded = true;
      }
      return offer;
    });
    setCampaign(
      (prevState) => prevState && { ...prevState, offers: newOffers }
    );
  };

  const openSingle = (
    offer: Offer,
    isSave: boolean,
    isAutoEmail: boolean,
    slot: number,
    isShare?: boolean
  ) => {
    if (isAutoEmail) {
      const params = { offer, isSave, isAuto: true, slot };
      window.top?.postMessage(
        { event: "emailPopupEmbedded", load: params },
        "*"
      );
    } else {
      window.parent.postMessage(
        {
          event: "openSingleEmbedded",
          load: { offer, isSave, slot, isShare: isShare || false },
        },
        "*"
      );
    }
  };

  const handleAdd = (offer: Offer, value: boolean, slot: number) => {
    setSelectedOfferStorage({
      id: offer.id,
      link: offer.trackingUrl,
      active: value,
    });
    if (campaign) {
      const newOffers: Offer[] = campaign.offers.map((_offer: Offer) => {
        if (_offer.id === offer.id) {
          _offer.isAdded = value;
        }
        return _offer;
      });
      setCampaign(
        (prevState) => prevState && { ...prevState, offers: newOffers }
      );
    } else {
      const _single = { ...singleOffer, isAdded: value };
      setSingle((prevState) => prevState && { ...prevState, isAdded: value });
      window.top?.postMessage(
        { event: "singleChange", offer: _single, slot },
        "*"
      );
    }

    value &&
      campaign &&
      getTrackingEvent({
        event: "onselectoffer",
        offerId: offer.id,
        retailerId: campaign.company_Id,
        campaignId: campaign.id,
        placementId,
        placementName,
        tid: settingsTemplate.identifier,
        slot,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      });
  };

  const handleSelectAll = () => {
    if (!campaign) {
      return;
    }

    const selectedLength = campaign.offers.filter((o) =>
      selectedOffers.includes(o.id)
    ).length;
    const offerLength = campaign?.offers.length;
    const shouldRemove = selectedLength === offerLength;

    campaign.offers.forEach((offer: Offer) => {
      handleAdd(offer, !shouldRemove, offer.slot);
    });
  };

  const setSelectedOfferStorage = (storage: SelectedOfferStorage) => {
    window.top?.postMessage({ event: "setSelectedOffer", load: storage }, "*");
  };

  useEffect(() => {
    if (campaignRes) {
      checkSelectedOffers(selectedOffers, campaignRes);
      setCampaign(campaignRes);
      if (campaignRes && !singleOffer) {
        // (hasOpen) && getTrackingEvent({ event: 'onmainbuttonclick', retailerId: campaignRes?.company_Id, campaignId: campaignRes.id, placementId, placementName, tid: settingsTemplate.identifier });
        getTrackingEvent({
          event: "ontemplateopen",
          retailerId: campaignRes.company_Id,
          campaignId: campaignRes.id,
          placementId,
          placementName,
          tid: settingsTemplate.identifier,
          pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
        });
      }
      // setHasOpen(true);
    }
    // eslint-disable-next-line
  }, [campaignRes]);

  return (
    <>
      {singleOffer && (
        <>
          {isEmail ? (
            <LayoutEmail
              settings={layoutSettings.popup}
              templateSettings={layoutSettings.settings}
              emailPopupOffer={singleOffer}
              isEmbedded={true}
              setIsEmail={setIsEmail}
              setSingle={setSingle}
              prePopulateEmail={preEmail}
              campaignObj={campaignRes}
              campaignId={campaignRes.id}
              placementId={placementId}
              placementName={placementName}
              slot={slot}
            ></LayoutEmail>
          ) : (
            <>
              {campaign?.id && settings && (
                <TemplateSingle
                  slot={slot}
                  settings={settings}
                  isInline={true}
                  offer={singleOffer}
                  isSave={isSave || isSingleSave}
                  isShare={isShare || false}
                  placementId={placementId}
                  placementName={placementName}
                  settingsTemplate={settingsTemplate}
                  campaignObj={campaign}
                  displayOnly={displayOnly}
                  campaignId={campaign?.id}
                  companyId={campaign.company_Id}
                  updateCampaign={handleAdd}
                  setPreEmail={setPreEmail}
                  preEmail={preEmail || email}
                  closeSignle={handleCloseSingle}
                />
              )}
            </>
          )}
        </>
      )}

      <div
        className={`inline-wrapper  ${settings?.mainLayout?.align} ${
          singleOffer || isEmail ? "hidden" : "displayed"
        }`}
        style={{
          padding: `0 ${settings?.embeddedElement?.margin || "0"}${
            settings?.embeddedElement?.marginType || "%"
          }`,
        }}
      >
        <div
          className={`inline-popup`}
          style={{
            backgroundColor: settings?.mainLayout.backgroundColor,
            fontFamily: settings?.mainLayout.fontFamily,
            color: settings?.mainLayout.textColor,
            height: `${settings?.embeddedElement?.height}px`,
          }}
        >
          <div className={`inline-title`}>
            {buttonShape === ButtonShapes.squareImage && (
              <>
                <div className="inline-title-icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.2 10V19H2.8V10M10 19V5.5M10 5.5H5.95C5.35326 5.5 4.78097 5.26295 4.35901 4.84099C3.93705 4.41903 3.7 3.84674 3.7 3.25C3.7 2.65326 3.93705 2.08097 4.35901 1.65901C4.78097 1.23705 5.35326 1 5.95 1C9.1 1 10 5.5 10 5.5ZM10 5.5H14.05C14.6467 5.5 15.219 5.26295 15.641 4.84099C16.0629 4.41903 16.3 3.84674 16.3 3.25C16.3 2.65326 16.0629 2.08097 15.641 1.65901C15.219 1.23705 14.6467 1 14.05 1C10.9 1 10 5.5 10 5.5ZM1 5.5H19V10H1V5.5Z"
                      stroke={settings?.mainLayout.headerTextColor}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            )}

            {buttonShape === ButtonShapes.squareImageLock && (
              <>
                <div className="inline-title-icon">
                  {displayOnly ? (
                    <svg
                      width="42"
                      height="47"
                      viewBox="0 0 42 47"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.88889 21.25V12.25C9.88889 9.26631 11.0595 6.40483 13.1433 4.29505C15.227 2.18526 18.0532 1 21 1C23.9469 1 26.773 2.18526 28.8567 4.29505C30.9405 6.40483 32.1111 9.26631 32.1111 12.25V21.25M5.44444 21.25H36.5556C39.0102 21.25 41 23.2647 41 25.75V41.5C41 43.9853 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9853 1 41.5V25.75C1 23.2647 2.98985 21.25 5.44444 21.25Z"
                        stroke={settings?.mainLayout.headerTextColor}
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="42"
                      height="47"
                      viewBox="0 0 42 47"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.88889 21.2554V12.258C9.88613 9.46896 10.9073 6.77834 12.7542 4.7085C14.601 2.63865 17.1419 1.33726 19.8834 1.05697C22.6248 0.776667 25.3714 1.53746 27.5899 3.19164C29.8084 4.84583 31.3406 7.2754 31.8889 10.0087M5.44444 21.2572H36.5556C39.0102 21.2572 41 23.2714 41 25.7559V41.5013C41 43.9859 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9859 1 41.5013V25.7559C1 23.2714 2.98985 21.2572 5.44444 21.2572Z"
                        stroke={settings?.mainLayout.headerTextColor}
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
              </>
            )}

            <span
              className={`inline-title-label`}
              style={{
                fontSize: settings?.mainLayout.headerFontSize + "px",
                fontWeight: settings?.mainLayout.headerFontWeight,
                color: settings?.mainLayout.headerTextColor,
              }}
            >
              {settings?.mainLayout.headerText || ""}
            </span>
          </div>

          <div
            className={`inline-description`}
            style={{
              fontSize: settings?.mainLayout.subHeaderFontSize + "px",
              fontWeight: settings?.mainLayout.subHeaderFontWeight,
              color: settings?.mainLayout.subHeaderTextColor,
            }}
          >
            {settings?.mainLayout.subHeaderText || ""}
          </div>

          {displayOnly && campaign?.campaignGeneralOptions?.showAddToOrder && (
            <PreCountAndSelectAll
              offerLength={campaign?.offers.length}
              countText={settings?.mainLayout?.countText}
              selectText={settings?.mainLayout?.selectText}
              showCheckboxSelect={
                settings?.otherSettings?.showCheckboxSelect || false
              }
              showSelectAll={settings?.otherSettings?.showSelectAll || false}
              showCount={true}
              noOfSelected={
                campaign?.offers.filter((o) => selectedOffers.includes(o.id))
                  .length
              }
              handleSelectAll={handleSelectAll}
              padding={"0 40px"}
            />
          )}

          <div className={`inline-swiper`} ref={swiperParentRef}>
            {campaign && campaign?.offers.length > 0 && settings && (
              <SwiperComponent
                settings={settings}
                campaign={campaign}
                emailSentIds={idsEmailSent}
                settingsTemplate={settingsTemplate}
                displayOnly={displayOnly}
                campaignId={campaign.id}
                placementId={placementId}
                placementName={placementName}
                openSingle={openSingle}
                onAdd={handleAdd}
              ></SwiperComponent>
            )}
          </div>

          <div className="inline-footer">
            {displayOnly ? (
              <>
                {campaign?.campaignGeneralOptions.emailCaptureOnOffers && (
                  <>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="12"
                        cy="12"
                        r="12"
                        fill={settings?.dealLayout.backgroundColor}
                      />
                      <path
                        d="M18 9.125C18 8.50625 17.46 8 16.8 8H7.2C6.54 8 6 8.50625 6 9.125M18 9.125V15.875C18 16.4938 17.46 17 16.8 17H7.2C6.54 17 6 16.4938 6 15.875V9.125M18 9.125L12 13.0625L6 9.125"
                        stroke={settings?.dealLayout.textColor}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span>Pick your gifts and receive by e-mail</span>
                  </>
                )}
              </>
            ) : (
              <>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z"
                    fill={settings?.mainLayout.textColor}
                  />
                </svg>
                <span>
                  By selecting the option above you will be directed to a third
                  party website. Any use of third party websites are not subject
                  to our Privacy Policy, and we encourage you to also read their
                  privacy statements
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewEmbeddedS;
